import React from 'react';
import './App.css';
import {
    FaInstagram,
    FaTwitter,
    FaTelegramPlane,
    FaLinkedinIn,
    FaYoutube,
} from 'react-icons/fa';

function App() {
    return (
        <div className="container">
            <header>
                <img src={process.env.PUBLIC_URL + '/images/photo.jpg'} alt="Rail Akhmetov" className="profile-photo" />
                <h1>Rail Akhmetov</h1>
                <p>I build my own mobile apps.</p>
            </header>

            <main>
                <section>
                    <h2>Current Apps</h2>
                    <ul>
                        <li>App Name 1</li>
                        <li>App Name 2</li>
                        {/* Add more apps as needed */}
                    </ul>
                </section>

                <section>
                    <h2>Past Projects</h2>
                    <ul>
                        <li>Project Name 1</li>
                        <li>Project Name 2</li>
                        {/* Add more projects as needed */}
                    </ul>
                </section>

                <section>
                    <h2>Career & Education</h2>
                    <p>
                        {/* Add details about your past career and education */}
                    </p>
                </section>

                <section>
                    <h2>Hobbies</h2>
                    <ul>
                        <li>
                            Chess
                            <ul>
                                <li><a href="https://www.chess.com/" target="_blank" rel="noopener noreferrer">Chess.com</a></li>
                                <li><a href="https://lichess.org/" target="_blank" rel="noopener noreferrer">Lichess</a></li>
                                <li><a href="https://www.fide.com/" target="_blank" rel="noopener noreferrer">FIDE</a></li>
                            </ul>
                        </li>
                        <li>Calisthenics</li>
                        <li>
                            Reading
                            <ul>
                                <li><a href="https://www.goodreads.com/" target="_blank" rel="noopener noreferrer">Goodreads</a></li>
                            </ul>
                        </li>
                        <li>
                            Movies
                            <ul>
                                <li><a href="https://www.kinopoisk.ru/" target="_blank" rel="noopener noreferrer">Kinopoisk</a></li>
                            </ul>
                        </li>
                    </ul>
                </section>
            </main>

            <footer>
                <h2>Connect with Me</h2>
                <ul className="social-links">
                    <li><a href="your-instagram-url" target="_blank" rel="noopener noreferrer"><FaInstagram /></a></li>
                    <li><a href="your-x-url" target="_blank" rel="noopener noreferrer"><FaTwitter /></a></li>
                    <li><a href="your-telegram-url" target="_blank" rel="noopener noreferrer"><FaTelegramPlane /></a></li>
                    <li><a href="your-linkedin-url" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a></li>
                    <li><a href="your-youtube-url" target="_blank" rel="noopener noreferrer"><FaYoutube /></a></li>
                </ul>
            </footer>
        </div>
    );
}

export default App;
